import styles from "./read.module.scss";
import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import Item from "./listItem/item";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";
import PagingComponent from "../../components/pagingComponent/pagingComponent";
import { queryObject } from "../../types/query.types";
import { getShopsList } from "../../requests/shops";
import { IShop } from "../../types/shop.types";
import SearchInput from "../../components/SearchInput/SearchInput";
import { useSearchParams } from "react-router-dom";
import ListPageHeader from "../referral/ListPageHeader/ListPageHeader";
import FiltersContainer from "../../components/Filters/FiltersContainer";
import ListHeader from "./ListHeader/ListHeader";

const INITIAL_SHIPPING_QUERY: queryObject = {
  field: "",
  value: "",
  limit: 10,
  offset: 0,
  sort: "",
};

export default function Shops() {
  const [searchParams] = useSearchParams();
  const [query, setQuery] = useState<queryObject>(INITIAL_SHIPPING_QUERY);
  const { data, isLoading, refetch } = useQuery(
    ["shop-list", query],
    () => getShopsList(formatedQuery()),
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );

  useEffect(() => {
    if (searchParams.get("name")) {
      setQuery((prev) => ({ ...prev, value: searchParams.get("name") || "" }));
    }
  }, [searchParams]);

  if (isLoading) {
    return <LoadingSpinner color="gold" />;
  }

  if (!data || !data.shops) {
    return <>Error data is missing !</>;
  }

  return (
    <div className={styles["list-page"]}>
      <ListPageHeader title={"Boutiques"} />
      <FiltersContainer>
        <SearchInput query={query.value || ""} setQuery={handleSearchInput} />
      </FiltersContainer>
      <div className={styles["list-container"]}>
        <div className={styles["paging-component-top"]}>
          <PagingComponent
            query={query}
            setQuery={setQuery}
            totalCount={data?.totalCount}
          />
        </div>
        <div className={styles["list"]}>
          <table className={styles["table"]}>
            <ListHeader />
            <tbody>
              {data?.shops.map((shop: IShop, index: number) => {
                return <Item key={index} refetch={refetch} shop={shop} />;
              })}
            </tbody>
          </table>
        </div>
        <div className={styles["paging-component"]}>
          <PagingComponent
            query={query}
            setQuery={setQuery}
            totalCount={data?.totalCount}
          />
        </div>
      </div>
    </div>
  );

  function formatedQuery() {
    let formatedQuery: string = `?limit=${
      query.limit
    }&sort_field=workshopPrefixId&sort_direction=asc&offset=${
      query.offset * query.limit
    }`;

    if (query.value) {
      formatedQuery += `&$or=${query.value}`;
    }
    return formatedQuery;
  }

  function handleSearchInput(value: string) {
    setQuery((prev) => ({ ...prev, value: value }));
  }
}
