import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import Create from "../create/create";
import { fetchQuote } from "../../../requests/quote";
import LoadingSpinner from "../../../components/loadingSpinner/loadingSpinner";
import style from "./edit.module.scss";

export default function Edit() {
  const { id } = useParams();
  const { data, isLoading, refetch } = useQuery(["quote", id], () => fetchQuote(id), {
    refetchOnWindowFocus: false,
  });

  if (isLoading || !data) {
    return <LoadingSpinner color="gold" />;
  }

  return <Create data={data} refetch={refetch} />;
}
