import { SearchOutlined } from "@mui/icons-material";
import { IProduct } from "../../../../../../types/logistic.types";
import PrintLabelButton from "../../../../../PrintLabel/PrintLabel";
import ProductGroupLogo from "../../../../../ProductGroupLogo/ProductGroupLogo";
import State from "../../../../../StateItem/State";
import WorkshopId from "../../../../../workshopId/workshopId";
import ProductImageList from "../ProductImageList/ProductImageList";
import styles from "./productSheet.module.scss";
import { useNavigate } from "react-router-dom";
import Button from "../../../../../Button/Button";

interface ProductSheetProps {
  product: IProduct;
}

export default function ProductSheet({ product }: ProductSheetProps) {
  const imagesList = (product.incomingPicturesList || []).concat(
    product.outgoingPicturesList || []
  );

  const navigate = useNavigate();

  return (
    <div className={styles["product-sheet"]}>
      <div className={styles["header"]}>
        <State state={product.status} className={styles["product-state"]} />
        <div className={styles["header-right"]}>
          <Button
            onClick={() =>
              navigate(`/quick-access/quality-control-section/${product.id}`)
            }
            className={styles["control-link-button"]}
          >
            <SearchOutlined />
          </Button>
          <PrintLabelButton product={product} />
          <WorkshopId workshopId={product.workshopId} size={"xxx-small"} />
        </div>
      </div>
      <ProductInformation product={product} />
      <ProductImageList imagesList={imagesList} />
    </div>
  );
}

interface ProductInformationProps {
  product: IProduct;
}

function ProductInformation({ product }: ProductInformationProps) {
  return (
    <div className={styles["product-information"]}>
      <div className={styles["product-information-line"]}>
        <div className={styles["product-group-brand"]}>
          <ProductGroupLogo
            className={styles["product-group-logo"]}
            productGroup={product.productGroup}
          />
          <p className={styles["brand"]}>{product.brand}</p>
        </div>
      </div>
    </div>
  );
}
