import styles from "./overviewHeader.module.scss";
import { ITracker } from "../../../../types/tracker.types";
import { toast } from "react-toastify";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_SUCCESS_OPTIONS,
} from "../../../../utils/toast.options";
import { updateTrackerState } from "../../../../requests/tracker";
import { useState } from "react";
import OverviewContact from "./OverviewContact/OverviewContact";
import OverviewAction from "./OverviewAction/OverviewAction";
import OverviewReference from "./OverviewReference/OverviewReference";
import { OverviewTabs } from "../../../../types/overview.types";

interface OverviewHeaderProps {
  tracker: ITracker;
  refetch: () => void;
  onChangeTab: (tab: OverviewTabs) => void;
}

export default function OverviewHeader({
  tracker,
  refetch,
  onChangeTab,
}: OverviewHeaderProps) {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  if (!tracker) {
    return <></>;
  }

  return (
    <div className={styles["overview-container-top"]}>
      <OverviewReference tracker={tracker} />
      <OverviewContact
        contactId={tracker?.contact?.documentId}
        trackId={tracker.id}
      />
      <OverviewAction
        tracker={tracker}
        onClosePaymentModal={closePaymentModal}
        onTrackerStateChange={handleChangeTrackerState}
        onChangeTab={onChangeTab}
      />
    </div>
  );

  async function handleChangeTrackerState(state: string) {
    if (!tracker.id) {
      return;
    }
    try {
      await updateTrackerState(state, tracker.id);
      refetch();
      toast.success(
        "L'état à était mis à jour avec succès.",
        TOAST_SUCCESS_OPTIONS
      );
    } catch (error) {
      toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
    }
  }

  function closePaymentModal() {
    setModalIsOpen(!modalIsOpen);
    refetch();
  }
}
