import { useEffect, useMemo, useState } from "react";
import { IQuote, IShippingMethod } from "../../../../types/accounting.types";
import styles from "./shippingMode.module.scss";
import { Check, Edit } from "@mui/icons-material";
import translate from "../../../../translation";
import { useQuery } from "react-query";
import { fetchShippingMethodsList } from "../../../../requests/shippingMethod";
import LoadingSpinner from "../../../../components/loadingSpinner/loadingSpinner";
import Price from "../../../../components/Price/Price";

interface ShippingModeProps {
  quote: any;
  updateShippingMethod: (value: IShippingMethod) => void;
  handleUpdateDocument: () => void;
}

export default function ShippingMode({
  quote,
  updateShippingMethod,
  handleUpdateDocument,
}: ShippingModeProps) {
  const [editShippingMethod, setEditShippingMethod] = useState<boolean>(false);
  const [shippingMethod, setShippingMethod] = useState<IShippingMethod>(
    quote.shipping
  );
  const { data, isLoading } = useQuery(
    "quote-edit-discount",
    () => fetchShippingMethodsList(),
    {
      refetchOnWindowFocus: false,
    }
  );
  useEffect(() => {
    updateShippingMethod(shippingMethod);
  }, [shippingMethod]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!data) {
    return <></>;
  }
  const { shippingMethodsList } = data;

  const finalShippingMethodsList = shippingMethodsList.filter(
    (sm) =>
      sm.shippingService === "INTERNAL" ||
      sm.shippingService === "CHRONOPOST-2SHOP" ||
      sm.shippingService === "DROP-OR-COLLECT"
  );

  return (
    <div className={styles["discount-container"]}>
      <div className={styles["title-section"]}>
        <div className={styles["title"]}>
          Livraison
          {quote.operationsTotal > quote.shipping.freeShippingMinimumAmount &&
            ` (gratuite) > ${quote.shipping.freeShippingMinimumAmount / 100} €`}
        </div>
        <button className={styles["edit-button"]} onClick={handleEditShipping}>
          {editShippingMethod ? <Check /> : <Edit />}
        </button>
      </div>
      <div className={styles["table-container"]}>
        {quote && quote.shipping ? (
          <table>
            <tbody>
              <tr>
                <th>{"Type"}</th>
                <th>{"Prix"}</th>
              </tr>
              {editShippingMethod ? (
                <tr>
                  <td>
                    <select
                      onChange={handleShippingMethodChange}
                      value={shippingMethod?.shippingService}
                    >
                      {finalShippingMethodsList &&
                        finalShippingMethodsList.map(
                          (shippingMethod: IShippingMethod, key: number) => {
                            return (
                              <option
                                key={key}
                                value={shippingMethod.shippingService}
                              >
                                {translate(shippingMethod.shippingService)}
                              </option>
                            );
                          }
                        )}
                    </select>
                  </td>
                  <td>
                    <input
                      className={styles["shipping-price-input"]}
                      value={quote.shipping.price / 100}
                      onChange={handlePriceChange}
                      inputMode="numeric"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") handleEditShipping();
                      }}
                    />
                    €
                  </td>
                </tr>
              ) : (
                <tr>
                  <td className={styles["shipping-method-values"]}>
                    {translate(quote.shipping.shippingService)}
                  </td>
                  <td className={styles["shipping-method-values"]}>
                    <Price
                      className={styles["shipping-price-value"]}
                      price={
                        quote.shipping.totalPrice +
                        (quote.shipping.internationalFees || 0)
                      }
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        ) : (
          <div className={styles["not-found-message"]}>aucune livraison</div>
        )}
      </div>
    </div>
  );

  function handleShippingMethodChange({
    target,
  }: {
    target: EventTarget & HTMLSelectElement;
  }) {
    const found = shippingMethodsList?.find(
      (shippingMethod: IShippingMethod) =>
        shippingMethod.shippingService === target.value
    );
    found && setShippingMethod(found);
  }

  function handlePriceChange({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) {
    const newShippingMethod = { ...shippingMethod };
    if (target.value === "" || isNaN(parseInt(target.value))) {
      newShippingMethod.price = 0;
    } else {
      newShippingMethod.price = parseInt(target.value);
    }
    setShippingMethod(newShippingMethod);
  }

  function handleEditShipping() {
    setEditShippingMethod(!editShippingMethod);
    if (editShippingMethod) {
      handleUpdateDocument();
    }
  }
}
