import { useParams } from "react-router-dom";
import styles from "./read.module.scss";
import { getTracker } from "../../../requests/tracker";
import { useQuery } from "react-query";
import LoadingSpinner from "../../../components/loadingSpinner/loadingSpinner";
import { useRefetchOnModalClose } from "../../../hooks/useRefetchOnModalClose";
import OverviewHeader from "./OverviewHeader/OverviewHeader";
import LogisticSection from "./LogisticSection/LogisticSection";
import TabNavigation from "./TabNavigation/TabNavigation";
import { ReactNode, useState } from "react";
import { OverviewTabs } from "../../../types/overview.types";
import QuoteSection from "./QuoteSection/QuoteSection";
import CommentSection from "./CommentSection/CommentSection";
import EstimateSection from "./EstimateSection/EstimateSection";
import ShippingSection from "./ShippingSection/ShippingSection";
import OverviewHistory from "./OverviewHistory/OverviewHistory";
import EstimateTab from "./EstimateTab/EstimateTab";
import QuoteTab from "./QuoteTab/QuoteTab";
import PictureLoadingSpinner from "../../../components/loadingSpinner/pictureloadingSpinner";

export default function Read() {
  const { id } = useParams();
  const { data, isFetching, refetch } = useQuery(
    ["tracker", id],
    () => getTracker(id || ""),
    { enabled: Boolean(id), refetchOnWindowFocus: false }
  );
  const [selectedTab, setSelectedTab] = useState<OverviewTabs>("summary");
  useRefetchOnModalClose(refetch);

  if (isFetching) {
    return (
      <div className={styles["loading-container"]}>
        <PictureLoadingSpinner />
      </div>
    );
  }

  if (!data) {
    return <></>;
  }

  return (
    <div className={styles["overview-container"]}>
      <OverviewHeader
        tracker={data}
        refetch={refetch}
        onChangeTab={handleSelectTab}
      />
      <TabNavigation
        selectedTab={selectedTab}
        onSelect={handleSelectTab}
        tracker={data}
      />
      <div className={styles["body"]}>{tabsDispatch()}</div>
    </div>
  );

  function handleSelectTab(tab: OverviewTabs) {
    setSelectedTab(tab);
  }

  function tabsDispatch() {
    switch (selectedTab) {
      case "summary":
        return (
          <>
            <Left>
              <LogisticSection logisticId={data?.logistic?.documentId!} />
              <ShippingSection tracker={data!} refetch={refetch} />
              <EstimateSection estimateId={data?.estimate?.documentId!} />
            </Left>
            <Right>
              <CommentSection tracker={data!} refetch={refetch} />
              <QuoteSection quoteId={data?.quote?.documentId!} />
            </Right>
          </>
        );
      case "logistic":
        return <LogisticSection logisticId={data?.logistic?.documentId!} />;
      case "quote":
        return <QuoteTab quoteId={data?.quote?.documentId!} />;
      case "shipping":
        return <ShippingSection tracker={data!} refetch={refetch} />;
      case "estimation":
        return <EstimateTab estimateId={data?.estimate?.documentId!} />;
      case "history":
        return <OverviewHistory eventsList={data?.eventsList} />;
      default:
        return <></>;
    }
  }
}

interface LeftProps {
  children: ReactNode;
}

function Left({ children }: LeftProps) {
  return (
    <div className={styles["left"]} style={{ flex: 5, marginRight: "0.5rem" }}>
      {children}
    </div>
  );
}

interface RightProps {
  children: ReactNode;
}

function Right({ children }: RightProps) {
  return <div className={styles["right"]}>{children}</div>;
}
