import { IPeriod } from "../../../../../../types/shipping.types";
import styles from "./shippingDate.module.scss";

interface ShippingDateProps {
  period?: IPeriod;
}

export default function ShippingDate({ period }: ShippingDateProps) {
  if (!period) {
    return <>unexpected error shipping period is missing</>;
  }

  return (
    <div className={styles["time-range"]}>
      <p>
        {period?.start
          ? new Date(period?.start).toLocaleDateString("fr-FR", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            })
          : "indisponible" + ""}
      </p>
      <p className={styles["time"]}>
        {period &&
          new Date(period.start || "").toLocaleTimeString("fr-FR", {
            hour: "2-digit",
            minute: "2-digit",
          })}
        {" - "}
        {period &&
          new Date(period.end || "").toLocaleTimeString("fr-FR", {
            hour: "2-digit",
            minute: "2-digit",
          })}
      </p>
    </div>
  );
}
