import clsx from "clsx";
import Button from "../../../../../../components/Button/Button";
import { ModalContainer } from "../../../../../../components/modals/ModalContainer";
import { ProtectedComponent } from "../../../../../../components/protectedComponent/protectedComponent";
import { ITracker } from "../../../../../../types/tracker.types";
import {
  ESTIMATE_PENDING,
  ESTIMATE_REACHED,
  ESTIMATE_SENT,
  CART_PENDING,
  INCOMING_PENDING,
  INCOMING_TRANSITING,
  INCOMING_DELIVERED,
  COLLECTED,
  QUOTE_SENT,
  PAYED,
  REFURBISHED,
  CONTROLLED,
  DELIVERED,
  CANCELED,
  NON_FEASIBLE,
  trackerStateMap,
} from "../../../../../../utils/tracking.utils";
import styles from "./overviewActionDispatch.module.scss";
import { useState } from "react";
import PaymentModal from "../../../../../../components/modals/paymentModal/paymentModal";
import { useNavigate } from "react-router-dom";
import { OverviewTabs } from "../../../../../../types/overview.types";
import LogisticModal from "../../../../../../components/modals/logisticModal/logisticModal";
import QuoteModal from "../../../../../../components/modals/quoteModal/quoteModal";

interface OverviewActionDispatchProps {
  tracker: ITracker;
  onClosePaymentModal: () => void;
  onChangeTab: (tab: OverviewTabs) => void;
}

export default function OverviewActionDispatch({
  tracker,
  onClosePaymentModal,
  onChangeTab,
}: OverviewActionDispatchProps) {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [logisticModalIsOpen, setLogisticModalIsOpen] =
    useState<boolean>(false);
  const [quoteModalIsOpen, setQuoteModalIsOpen] = useState<boolean>(false);
  const trackerStep = trackerStateMap.get(tracker.state);
  const navigate = useNavigate();

  switch (trackerStep) {
    case ESTIMATE_PENDING:
      return (
        <>
          <Button className={styles["action-button"]} onClick={estimateAction}>
            Répondre à la demande
          </Button>
          <Button className={styles["action-button"]} onClick={checkInOrder}>
            Récéptionner la demande
          </Button>
        </>
      );
    case ESTIMATE_REACHED:
      return (
        <>
          <Button className={styles["action-button"]} onClick={estimateAction}>
            Répondre à la demande
          </Button>
          <Button className={styles["action-button"]} onClick={checkInOrder}>
            Récéptionner la demande
          </Button>
        </>
      );
    case ESTIMATE_SENT:
      return (
        <>
          <Button className={styles["action-button"]} onClick={estimateAction}>
            Modifier à la demande
          </Button>
          <Button className={styles["action-button"]} onClick={estimateAction}>
            Envoyer la demande
          </Button>
          <Button className={styles["action-button"]} onClick={checkInOrder}>
            Récéptionner la demande
          </Button>
        </>
      );
    case CART_PENDING:
      return <></>;
    case INCOMING_PENDING:
      return (
        <>
          <Button className={styles["action-button"]} onClick={checkInOrder}>
            Récéptionner la commande
          </Button>
          <Button className={styles["action-button"]} onClick={shippingAction}>
            Reprogrammer la collecte
          </Button>
          <Button className={styles["action-button"]}>*Envoyer RDV</Button>
        </>
      );
    case INCOMING_TRANSITING:
      return (
        <Button className={styles["action-button"]} onClick={checkInOrder}>
          Récéptionner la commande
        </Button>
      );
    case INCOMING_DELIVERED:
      return (
        <>
          <Button className={styles["action-button"]} onClick={checkInOrder}>
            Finaliser la récéption
          </Button>
          <Button className={styles["action-button"]}>
            *Envoyer un email personnalisé
          </Button>
        </>
      );
    case COLLECTED:
      return <></>;
    case QUOTE_SENT:
      return (
        <>
          {!tracker.payment && (
            <ProtectedComponent roles={["ROLE_ADMIN"]}>
              <Button
                className={clsx(
                  styles["payment-button"],
                  styles["action-button"]
                )}
                onClick={openPaymentModal}
              >
                Forcer un paiement
              </Button>
            </ProtectedComponent>
          )}
          <Button className={styles["action-button"]} onClick={quoteAction}>
            Envoyer devis
          </Button>
          <Button className={styles["action-button"]} onClick={quoteAction}>
            Modifier devis
          </Button>
          <ModalContainer isOpen={modalIsOpen} onCancel={onClosePaymentModal}>
            <PaymentModal tracker={tracker} onSuccess={onClosePaymentModal} />
          </ModalContainer>
        </>
      );
    case PAYED:
      return (
        <>
          <Button
            className={styles["action-button"]}
            onClick={handleOpenQuoteModal}
          >
            Générer facture
          </Button>
          <Button
            className={styles["action-button"]}
            onClick={handleOpenQuoteModal}
          >
            Envoi ReFashion
          </Button>
          <Button
            className={styles["action-button"]}
            onClick={openLogisticModal}
          >
            Mettre en réparation
          </Button>
          <ModalContainer
            isOpen={logisticModalIsOpen}
            onCancel={openLogisticModal}
          >
            <LogisticModal
              id={tracker.logistic.documentId}
              requiredActions={true}
              dueDate={tracker?.outgoingShipping?.period?.start}
              onToolSelect={openLogisticModal}
            />
          </ModalContainer>
          <ModalContainer
            isOpen={quoteModalIsOpen}
            onCancel={handleOpenQuoteModal}
          >
            <QuoteModal
              id={tracker.quote.documentId}
              invoice={tracker.invoice}
              requiredActions={true}
            />
          </ModalContainer>
        </>
      );
    case REFURBISHED:
      return (
        <>
          <Button
            className={styles["action-button"]}
            onClick={openLogisticModal}
          >
            Contrôler les articles
          </Button>
          <Button
            className={styles["action-button"]}
            onClick={openLogisticModal}
          >
            Déplacer les articles
          </Button>
          <Button className={styles["action-button"]} onClick={shippingAction}>
            Reprogrammer la livraison
          </Button>
          <Button className={styles["action-button"]}>
            *Retard réparation
          </Button>
          <Button
            className={styles["action-button"]}
            onClick={openLogisticModal}
          >
            Imprimer tickets articles
          </Button>
          <Button className={styles["action-button"]}>
            *Approvisionnement matière
          </Button>
          <ModalContainer
            isOpen={logisticModalIsOpen}
            onCancel={openLogisticModal}
          >
            <LogisticModal
              id={tracker.logistic.documentId}
              requiredActions={false}
              dueDate={tracker?.outgoingShipping?.period?.start}
              onToolSelect={openLogisticModal}
            />
          </ModalContainer>
        </>
      );
    case CONTROLLED:
      return (
        <>
          <Button className={styles["action-button"]} onClick={shippingAction}>
            Reprogrammer la livraison
          </Button>
          <Button className={styles["action-button"]}>*Commande livré</Button>
        </>
      );
    case DELIVERED:
      return (
        <Button className={styles["action-button"]} disabled>
          *Créer SAV
        </Button>
      );
    case CANCELED:
      return (
        <Button className={styles["action-button"]} onClick={shippingAction}>
          Plannifier la livraison
        </Button>
      );
    case NON_FEASIBLE:
      return (
        <Button className={styles["action-button"]} onClick={estimateAction}>
          Modifier la demande
        </Button>
      );
    default:
      return <></>;
  }

  function estimateAction() {
    onChangeTab("estimation");
  }

  function shippingAction() {
    onChangeTab("shipping");
  }

  function quoteAction() {
    onChangeTab("quote");
  }

  function checkInOrder() {
    if (!tracker?.incomingShipping?.shippingService) {
      window.alert(
        "Veuillez créer une collecte avant de pouvoir réceptionner la commande (solution temporaire !)"
      );
      return;
    }
    if (tracker?.incomingShipping?.shippingService === "DROP-OR-COLLECT") {
      navigate(`/quick-access/in-store-drop-off/generate/${tracker.id}`);
    } else {
      navigate(`/quick-access/cart-to-logistic/generate/${tracker.id}`);
    }
  }

  // function checkInOrder() {
  //   navigate(`/quick-access/in-store-drop-off/generate/${tracker.id}`);
  // }

  function openPaymentModal() {
    setModalIsOpen(true);
  }

  function openLogisticModal() {
    setLogisticModalIsOpen(!logisticModalIsOpen);
  }

  function handleOpenQuoteModal() {
    setQuoteModalIsOpen(!quoteModalIsOpen);
  }
}
