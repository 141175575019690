import { useLoaderData, useNavigate } from "react-router-dom";
import style from "./read.module.scss";
import { fetchQuote } from "../../../requests/quote";
import { IOperation, IQuote } from "../../../types/accounting.types";
import { Edit, Launch, Mail, Phone } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { ProtectedComponent } from "../../../components/protectedComponent/protectedComponent";
import translate from "../../../translation";
import useLogout from "../../../hooks/useLogout";
import { IProduct } from "../../../types/logistic.types";
import { authloader } from "../..";
import Price from "../../../components/Price/Price";

export async function loader({
  request,
  params,
}: {
  request: Request;
  params: any;
}) {
  try {
    const quotes = await fetchQuote(params.id);
    authloader();
    return quotes;
  } catch (error: any) {
    throw error;
  }
}

let controller = new AbortController();

export default function Read() {
  const data = useLoaderData() as IQuote;

  const navigate = useNavigate();

  useLogout(data);

  return (
    <div className={style["read-one"]}>
      <div className={style["read-one-container"]}>
        <div className={style["top"]}>
          <div className={style["top-left"]}>
            <span className={style["created-at"]}>
              crée le{" "}
              {data.createdAt && new Date(data.createdAt).toLocaleDateString()}
            </span>
            <div className={style["nom-complet"]}>
              {data.contact && data.contact.givenname}{" "}
              {data.contact && data.contact.familyname}
              <div
                className={style["access-button"]}
                onClick={() => navigate(`/overview/${data?.id}`)}
              >
                <Launch className={style["access-button-icon"]} />
              </div>
            </div>
            <div className={style["coordinates"]}>
              <Link
                className={style["mail"]}
                to={`mailto:${data.contact && data.contact.email}`}
              >
                <span className={style["coordinates-item"]}>
                  <Mail className={style["icon"]} />{" "}
                  {data.contact && data.contact.email}
                </span>
              </Link>
              <Link
                className={style["phone"]}
                to={`tel:${data.contact && data.contact.phone}`}
              >
                <span className={style["coordinates-item"]}>
                  <Phone className={style["icon"]} />
                  {data.contact && data.contact.phone}
                </span>
              </Link>
            </div>
          </div>
          <div className={style["totalprice-container"]}>
            total : <Price className={style["totalprice"]} price={data.total} />
          </div>
        </div>

        <hr />
        <div>
          Date d'envoi :{" "}
          {data.sentAt && new Date(data.sentAt).toLocaleDateString()}
        </div>
        <div>
          Date de réponse :{" "}
          {data.repliedAt && new Date(data.repliedAt).toLocaleDateString()}
        </div>
        <hr />
        <div className={style["state-delay"]}>
          <div className={style["select-state"]}>
            <div>Status : {translate(data?.state || "")}</div>
            <div>Délai estimé : {` ${data?.minimumDurationDays} jours`}</div>
            <div>Délai express : {`${data?.maximumDurationDays} jours`}</div>
          </div>
        </div>
        <hr />
        <div className={style["product-list"]}>
          {`Articles (${data.productsList && data.productsList.length}) :`}
          {data.productsList &&
            data.productsList.map((product: IProduct, key: number) => {
              return (
                <div key={key} className={style["product"]}>
                  <div className={style["product-info"]}>
                    <div>{product.brand}</div>
                    {"Total article : "}
                    <Price price={product.totalPrice} />
                  </div>
                  {`Opérations (${product.operationsList.length}) :`}
                  <div>
                    {product.operationsList.map(
                      (operation: IOperation, key: number) => {
                        return (
                          <div
                            className={`${style["operation"]} ${
                              operation.canceled
                                ? style["crossed"]
                                : style["bold"]
                            }`}
                            key={key}
                          >
                            <div>{operation.description}</div>
                            <Price price={operation.price} />
                            <div>{operation.comments}</div>
                          </div>
                        );
                      }
                    )}
                  </div>
                  <div>{product.comments}</div>
                </div>
              );
            })}
        </div>
        <hr />
        <div>
          Coût total des opérations : <Price price={data.operationsTotal} />
        </div>
        <div>
          Cross selling : {data.salesTotal} <Price price={data.salesTotal} />
        </div>
        <div>
          Frais de livraison : <Price price={data.shipping?.price} />
        </div>
        <div>
          Réduction : <Price price={data.discountTotal} />
        </div>
        <hr />
        <div>Commentaires : {data.comments}</div>
      </div>
      <ProtectedComponent roles={["ROLE_ADMIN"]}>
        <div className={style["tools"]}>
          <Link
            onClick={() => window.scrollTo(0, 0)}
            to={`/quotes/edit/${data.id}`}
            className={style["tools-icon"]}
          >
            <Edit className={style["edit-icon"]} />
          </Link>
        </div>
      </ProtectedComponent>
    </div>
  );
}
