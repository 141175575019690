import { useState } from "react";
import { useQuery } from "react-query";
import { getEstimate } from "../../../../requests/estimate";
import OverviewSection from "../OverviewSection/OverviewSection";
import styles from "./estimateSection.module.scss";
import PictureLoadingSpinner from "../../../../components/loadingSpinner/pictureloadingSpinner";
import DataError from "../../../../components/errors/DataError/DataError";
import { ModalContainer } from "../../../../components/modals/ModalContainer";
import EstimateModal from "../../../../components/modals/estimateModal/estimateModal";
import OverviewSectionHeader from "../OverviewSectionHeader/OverviewSectionHeader";
import Button from "../../../../components/Button/Button";
import Image from "../../../../components/image/Image";
import State from "../../../../components/StateItem/State";

interface EstimateSectionProps {
  estimateId: string;
}

export default function EstimateSection({ estimateId }: EstimateSectionProps) {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const { data, isLoading, refetch } = useQuery(
    ["estimate", estimateId],
    () => getEstimate(estimateId),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(estimateId),
    }
  );

  if (!estimateId) {
    return <></>;
  }

  if (isLoading) {
    return (
      <OverviewSection className={styles["loading-section"]}>
        <PictureLoadingSpinner />
      </OverviewSection>
    );
  }

  if (!data) {
    return (
      <OverviewSection className={styles["loading-section"]}>
        <DataError error={"logistic"} />
      </OverviewSection>
    );
  }

  return (
    <OverviewSection className={styles["estimate-section"]}>
      <OverviewSectionHeader
        title={`Estimation`}
        className={styles["estimate-section-header"]}
        onClick={toogleEstimateModal}
      >
        <State state={data?.state} className={styles["state"]} />
      </OverviewSectionHeader>
      <div className={styles["body"]}>
        <Image
          className={styles["image-miniature"]}
          src={data.imagesList[0] || ""}
          showTools={false}
          pictureList={data.imagesList}
        />
        <p className={styles["comment"]}>{data.comments}</p>
      </div>
      {data.state === "ESTIMATE_PENDING" && (
        <Button>Répondre à la demande</Button>
      )}
      {/* {data.state === "ESTIMATE_SENT" && <Button>Modifier la demande</Button>} */}
      <ModalContainer isOpen={modalIsOpen} onCancel={toogleEstimateModal}>
        <EstimateModal id={estimateId} onToolSelect={toogleEstimateModal} />
      </ModalContainer>
    </OverviewSection>
  );

  function toogleEstimateModal() {
    setModalIsOpen(!modalIsOpen);
  }
}
