import { useNavigate } from "react-router-dom";
import { ITracker } from "../../../../../types/tracker.types";
import styles from "./unfinishedListItem.module.scss";
import State from "../../../../../components/StateItem/State";
import CenterCell from "../../../../../components/CenterCell/CenterCell";
import ShippingServiceLogo from "../../../../../components/ShippingServiceLogo/ShippingServiceLogo";
import clsx from "clsx";
import { isDesktop } from "react-device-detect";
import { Link } from "react-router-dom";

interface UnfinishedListItemProps {
  tracker: ITracker;
}

export default function UnfinishedListItem({
  tracker,
}: UnfinishedListItemProps) {
  const navigate = useNavigate();

  let link = `/overview/${tracker.id}`;

  return (
    <tr
      // onClick={() => navigate(`/overview/${tracker.id}`)}
      className={clsx(
        styles["item"],
        isDesktop ? styles["hover"] : styles["active"]
      )}
    >
      <td className={styles["item-cell"]}>
        <Link to={link}>{tracker.contact?.displayname}</Link>
      </td>
      <td className={`${styles["item-cell"]} ${styles["status-box"]}`}>
        <Link to={link}>
          <CenterCell>
            <State state={tracker.state} />
          </CenterCell>
        </Link>
      </td>
      <td className={`${styles["item-cell"]} ${styles["status-box"]}`}>
        <Link to={link}>
          <CenterCell>
            <ShippingServiceLogo
              shippingService={tracker.outgoingShipping.shippingService}
            />
          </CenterCell>
        </Link>
      </td>
    </tr>
  );
}
