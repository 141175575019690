import { FormEvent, useEffect, useMemo } from "react";
import InputContainer from "../../../../../components/forms/inputs/inputContainer/inputContainer";
import AutoCompleteInput from "../../../../../components/forms/logisticForm/autoCompleteInput/autoCompleteInput";
import { fetchBrands, fetchCategories } from "../../../../../requests/logistic";
import { ICommentObject, IProduct } from "../../../../../types/logistic.types";
import {
  OPERATION_GROUP,
  PRODUCT_GROUP,
} from "../../../../../utils/logistic.init";
import styles from "./productForm.module.scss";
import FreshNewColorPicker from "../../../../../components/freshNewColorPicker/freshNewColorPicker";
import ImagesForm from "../../../../../components/forms/imagesForm/imagesForm";
import { deleteFile, streamFileMutation } from "../../../../../requests/file";
import { useMutation } from "react-query";
import AdditionalInfoSection from "./AdditionalInfoSection/AdditionalInfoSection";
import ProductGroupSelect from "./ProductGroupSelect/ProductGroupSelect";
import OperationGroupSelect from "./OperationGroupSelect/OperationGroupSelect";
import ColorPicker from "../../../../../components/ColorPicker/ColorPicker";
import SectionTitle from "../../../../../components/SectionTitle/SectionTitle";
import clsx from "clsx";

interface ProductFormProps {
  trackId: string;
  product: IProduct;
  validationError: string[];
  onChange: (product: IProduct) => void;
  setValidationError: (validationError: string[]) => void;
}

export default function ProductForm({
  trackId,
  product,
  validationError,
  setValidationError,
  onChange,
}: ProductFormProps) {
  const { mutate: addFile, isLoading } = useMutation(streamFileMutation, {
    onSuccess(data, variables, context) {
      let incomingPicturesList = [...(product.incomingPicturesList || [])];
      incomingPicturesList.push(data.message);
      onChange && onChange({ ...product, incomingPicturesList });
    },
    onError(error, variables, context) {},
  });

  const { mutate: deleteImage } = useMutation(deleteFile, {
    onSuccess(data, variables, context) {
      let incomingPicturesList = [...(product.incomingPicturesList || [])];
      if (data.message) {
        let fileIndex = incomingPicturesList.findIndex(
          (image) => image === data.filename
        );

        if (fileIndex >= 0) {
          incomingPicturesList.splice(fileIndex, 1);
          onChange && onChange({ ...product, incomingPicturesList });
        }
      }
    },
    onError(error, variables, context) {},
  });

  useEffect(() => {
    if (!PRODUCT_GROUP.includes(product.productGroup)) {
      handleChange({
        name: "operationGroup",
        value: "GENERIC_OPERATION",
      });
    }
  }, [product.productGroup]);

  const FITLERED_OPERATION_GROUP = useMemo(
    () => OPERATION_GROUP.filter((op) => op.includes(product.productGroup)),
    [product.productGroup]
  );

  return (
    <form onSubmit={handleSubmit}>
      <ProductGroupSelect
        onChange={handleProductGroupChange}
        productGroup={product.productGroup}
      />
      {PRODUCT_GROUP.includes(product.productGroup) && (
        <OperationGroupSelect
          onChange={handleOperationGroupChange}
          operationGroup={product.operationGroup}
          operationGroupList={FITLERED_OPERATION_GROUP}
        />
      )}
      {OPERATION_GROUP.includes(product.operationGroup) && (
        <>
          <SectionTitle title={"Couleur"} />
          <ColorPicker
            color={product.color}
            handleColorChange={(value: string) =>
              onChange({ ...product, color: value })
            }
            className={clsx(isNotValid("color"), styles["color-picker"])}
          />
          <AutoCompleteInput
            name={"brand"}
            dataValue={product.brand}
            index={0}
            onChange={handleChange}
            fetchRequest={fetchBrands}
            className={isNotValid("brand")}
            handleStartCorrection={handleStartCorrection}
          />
          <AutoCompleteInput
            name={"category"}
            dataValue={product.category ?? ""}
            index={0}
            onChange={handleChange}
            fetchRequest={fetchCategories}
            className={isNotValid("category")}
            handleStartCorrection={handleStartCorrection}
          />
          <InputContainer className={styles["input-container"]}>
            <label>Taille</label>
            <input
              type="text"
              name="size"
              onChange={({ target }) =>
                handleChange({ name: target.name, value: target.value })
              }
              value={product.size}
            />
          </InputContainer>
          <InputContainer className={styles["input-container"]}>
            <label>Ticket</label>
            <input
              type="text"
              inputMode="numeric"
              name="workshopId"
              onChange={({ target }) => {
                handleChange({ name: target.name, value: target.value });
              }}
              className={isNotValid("workshopId")}
              value={product.workshopId}
              placeholder="1-1-XXX"
              onFocus={AddTicketPrefix}
            />
          </InputContainer>
          <ImagesForm
            title={`incoming-cart-to-logistic`}
            imageList={product.incomingPicturesList || []}
            className={styles["images-form-container"]}
            deleteImage={(uri, index) => handleDeleteImageByName(uri)}
            setIsLoading={(v) => {}}
            onAddFile={handleAddImage}
            onDeleteFile={handleDeleteImage}
          />

          <AdditionalInfoSection
            defaultComment={product.comments}
            onChange={handleOnCommentChange}
          />
        </>
      )}
    </form>
  );

  async function handleAddImage(file: File) {
    addFile({ filename: `${trackId}-logistic.`, fileContent: file });
  }

  async function handleDeleteImageByName(filename: string) {
    deleteImage(filename);
  }

  async function handleDeleteImage(file: File) {
    deleteImage(file.name);
  }

  function handleProductGroupChange(productGroup: string) {
    handleChange({ name: "productGroup", value: productGroup });
  }

  function handleOperationGroupChange(operationGroup: string) {
    handleChange({ name: "operationGroup", value: operationGroup });
  }

  function handleChange({
    name,
    value,
  }: {
    name: string;
    value: string | number;
  }) {
    let newValues = { ...product, [name]: value };
    onChange(newValues);
  }

  function isNotValid(name: string): string {
    if (validationError.find((value: string) => value === name)) {
      return styles["validation-error"];
    }
    return "";
  }

  function handleOnCommentChange(commentsObject: ICommentObject) {
    const comments = JSON.stringify(commentsObject);
    onChange({ ...product, comments });
  }

  function handleStartCorrection(name: string) {
    const newValidation = validationError.filter(
      (value: string) => value !== name
    );
    setValidationError([...newValidation]);
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
  }

  function AddTicketPrefix({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) {
    if (!target.value.startsWith("1-1-") && !target.value) {
      handleChange({ name: target.name, value: "1-1-" + target.value });
    }
    handleStartCorrection("workshopId");
  }
}
