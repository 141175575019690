import { ITracker } from "../../../../types/tracker.types";
import OverviewSection from "../OverviewSection/OverviewSection";
import OverviewSectionHeader from "../OverviewSectionHeader/OverviewSectionHeader";
import ShippingInformation from "./ShippingInformation/ShippingInformation";
import styles from "./shippingSection.module.scss";

interface ShippingSectionProps {
  tracker: ITracker;
  refetch: () => void;
}

export default function ShippingSection({
  tracker,
  refetch,
}: ShippingSectionProps) {
  if (
    !tracker?.incomingShipping?.documentId &&
    !tracker?.outgoingShipping?.documentId
  ) {
    return (
      <OverviewSection className={styles["shipping-section"]}>
        <OverviewSectionHeader
          title={"Livraison & Collecte"}
          className={styles["quote-section-header"]}
        ></OverviewSectionHeader>
        <ShippingInformation
          shippingId={tracker?.incomingShipping?.documentId}
          tracker={tracker}
          direction={"collecte"}
          refetch={refetch}
        />
      </OverviewSection>
    );
  }

  return (
    <OverviewSection className={styles["shipping-section"]}>
      <OverviewSectionHeader
        title={"Livraison & Collecte"}
        className={styles["quote-section-header"]}
      ></OverviewSectionHeader>
      <ShippingInformation
        shippingId={tracker?.outgoingShipping?.documentId}
        tracker={tracker}
        direction={"livraison"}
        refetch={refetch}
      />
      <ShippingInformation
        shippingId={tracker?.incomingShipping?.documentId}
        tracker={tracker}
        direction={"collecte"}
        refetch={refetch}
      />
    </OverviewSection>
  );
}
