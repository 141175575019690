import { ITracker } from "../../../../../types/tracker.types";
import { dateDiffInDays } from "../../../../../utils/utils";
import styles from "./overviewReference.module.scss";

interface OverviewReferenceProps {
  tracker: ITracker;
}

export default function OverviewReference({ tracker }: OverviewReferenceProps) {
  return (
    <div className={styles["overview-reference"]}>
      <div className={styles["overview-reference-left"]}>
        <p className={styles["date"]}>
          {tracker.eventsList[0].date
            ? new Date(tracker?.eventsList[0]?.date).toLocaleDateString(
                "fr-FR",
                {
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                }
              )
            : "indisponible"}
        </p>
        <p className={styles["separator"]}>|</p>
        <p className={styles["track-id"]}>{`réf: ${tracker.id}`}</p>
      </div>
      <div className={styles["overview-reference-right"]}>
        <p className={styles["due-date"]}>
          {tracker?.outgoingShipping?.period?.start ? (
            <>
              {"Livraison prévue le "}
              {new Date(
                tracker?.outgoingShipping?.period?.start
              ).toLocaleDateString("fr-FR", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              })}{" "}
              {`(${dateDiffInDays(
                new Date(),
                new Date(tracker?.outgoingShipping?.period?.start)
              )} jours)`}
            </>
          ) : (
            ""
          )}
        </p>
      </div>
    </div>
  );
}
