import { dividerClasses } from "@mui/material";
import style from "./quoteModal.module.scss";
import { useQuery } from "react-query";
import { fetchQuote } from "../../../requests/quote";
import LoadingSpinner from "../../loadingSpinner/loadingSpinner";
import ContactInfoCard from "../../contactInfoCard/contactInfoCard";
import { Link } from "react-router-dom";
import { Edit } from "@mui/icons-material";
import { StatusItem } from "../../statusItem/statusItem";
import { useContext, useState } from "react";
import { useModalComponent } from "../../../context/modalComponent.context";
import translate from "../../../translation";
import { ICrossSellQuote, IOperation } from "../../../types/accounting.types";
import { IProduct } from "../../../types/logistic.types";
import QuoteReadSection from "./quoteReadSection";
import QuoteActionSection from "./quoteActionSection";
import QuoteValidateInvoice from "./quoteValidateInvoice";
import { IInvoice } from "../../../types/tracker.types";
import RefashionImagesUpload from "./refashionImagesUpload";

interface QuoteModalProps {
  id: string;
  invoice?: IInvoice;
  requiredActions?: boolean;
}

export default function QuoteModal({
  id,
  invoice,
  requiredActions,
}: QuoteModalProps) {
  const { data, error, isFetching, refetch } = useQuery(
    "quote-modal",
    () => fetchQuote(id),
    { refetchOnWindowFocus: false }
  );
  const [tabSelected, setTabSelected] = useState<
    "read" | "action" | "refashion"
  >(requiredActions ? "action" : "read");
  const { setModalIsOpen } = useModalComponent();

  return (
    <div className={style["quote-modal"]}>
      {!isFetching ? (
        <>
          {data && (
            <div className={style["read-document"]}>
              <ContactInfoCard
                contact={data.contact}
                className={style["quote-modal-contact-card"]}
                trackId={data?.trackId}
              />
              <div className={style["top-section"]}>
                <div className={style["tabs-section"]}>
                  <div
                    className={`${style["tab-item"]} ${
                      tabSelected === "read" && style["tab-selected"]
                    }`}
                    onClick={() => selectTab("read")}
                  >
                    Devis
                  </div>
                  <div
                    className={`${style["tab-item"]} ${
                      tabSelected === "action" && style["tab-selected"]
                    }`}
                    onClick={() => selectTab("action")}
                  >
                    Facture
                  </div>

                  <div
                    className={`${style["tab-item"]} ${
                      tabSelected === "refashion" && style["tab-selected"]
                    }`}
                    onClick={() => selectTab("refashion")}
                  >
                    Refashion
                  </div>
                </div>

                <div className={style["header-left"]}>
                  Devis
                  <span className={style["replied-at-date"]}>
                    {`(date de paiement: `}
                    {data.repliedAt
                      ? new Date(data.repliedAt).toLocaleDateString()
                      : "indisponible"}
                    {`)`}
                  </span>
                </div>
                <div className={style["top-section-right"]}>
                  <span className={style["delay"]}>
                    {`délais minimum (`}
                    <b>
                      {data.minimumDurationDays}
                      {` jours`}
                    </b>
                    {`) / délais maximum (`}
                    <b>
                      {data.maximumDurationDays}
                      {` jours`}
                    </b>
                    {`)`}
                  </span>
                  <Link
                    onClick={handleEdit}
                    to={`/quotes/edit/${data.id}`}
                    className={style["tools-icon"]}
                  >
                    <Edit className={style["edit-icon"]} />
                  </Link>
                  <div className={style["state-container"]}>
                    <StatusItem
                      data={{ state: data.state, documentId: data.id }}
                    />
                  </div>
                </div>
              </div>
              {handleTabChange()}
            </div>
          )}
        </>
      ) : (
        <div className={style["loading-container"]}>
          <LoadingSpinner color="gold" />
        </div>
      )}
    </div>
  );

  function handleTabChange() {
    switch (tabSelected) {
      case "read":
        return <>{data && <QuoteReadSection data={data} />}</>;
      case "action":
        return <>{data && <QuoteValidateInvoice trackId={data.trackId} />}</>;
      case "refashion":
        return (
          <>
            {data && (
              <RefashionImagesUpload quote={data} trackId={data.trackId} />
            )}
          </>
        );
      default:
        return <>{data && <QuoteReadSection data={data} />}</>;
    }
  }

  function selectTab(tab: "read" | "action" | "refashion") {
    setTabSelected(tab);
  }

  function handleEdit() {
    setModalIsOpen(false);
    window.scrollTo(0, 0);
  }
}
