import { ITracker } from "../../../types/tracker.types";
import styles from "./item.module.scss";
import { StatusItem } from "../../../components/statusItem/statusItem";
import { NavigateOptions, useNavigate } from "react-router-dom";
import logoChrono from "../../../assets/logo-chronopost-small.png";
import logoSalon from "../../../assets/logo-salon.png";
import logoGP from "../../../assets/favicon-32x32.png";
import CenterCell from "../../../components/CenterCell/CenterCell";
import clsx from "clsx";
import { isDesktop } from "react-device-detect";
import { Link } from "react-router-dom";
import { StoreOutlined } from "@mui/icons-material";

export default function Item({ data }: { data: ITracker }) {
  const navigate = useNavigate();

  function getIcon(shippingService: string) {
    if (shippingService && shippingService.includes("CHRONOPOST")) {
      return logoChrono
    } else if (shippingService && shippingService.includes("DROP")) {
      return logoSalon
    } else {
      return logoGP
    }
  }
  return (
    <tr
      className={clsx(
        styles["item"],
        isDesktop ? styles["hover"] : styles["active"]
      )}
      onClick={(ev) => {
        if (ev.ctrlKey) {
          window.open(`./overview/${data.id}`, "_blank")
        } else {
          navigate(`./${data.id}`)
        }
      }}
    >
      <td className={clsx(styles["item-cell"], styles["item-date"])}>
        {new Date(data.eventsList[0]?.date).toLocaleDateString("fr-FR")}
      </td>
      <td className={clsx(styles["item-cell"], styles["item-date"])}>
        {data.contact?.displayname}
      </td>
      <td className={`${styles["item-cell"]} ${styles["status-box"]}`}>
        <CenterCell>
          <StatusItem data={data} isGeneral />
        </CenterCell>
      </td>
      <td className={`${styles["item-cell"]} ${styles["status-box"]}`}>
        <CenterCell>
          <StatusItem data={data.estimate} />
        </CenterCell>
      </td>
      <td className={`${styles["item-cell"]} ${styles["status-box"]}`}>
        <CenterCell>
          <StatusItem data={data.cart} />
        </CenterCell>
      </td>
      <td className={`${styles["item-cell"]} ${styles["status-box"]}`}>
        <CenterCell>
          <StatusItem
            data={data.incomingShipping}
            image={getIcon(data.incomingShipping?.shippingService)}
          />
        </CenterCell>
      </td>
      <td className={`${styles["item-cell"]} ${styles["status-box"]}`}>
        <CenterCell>
          <StatusItem data={data.logistic} />
        </CenterCell>
      </td>
      <td className={`${styles["item-cell"]} ${styles["status-box"]}`}>
        <CenterCell>
          <StatusItem data={data.quote} />
        </CenterCell>
      </td>
      <td className={`${styles["item-cell"]} ${styles["status-box"]}`}>
        <CenterCell>
          <StatusItem
            data={data.outgoingShipping}
            image={getIcon(data.outgoingShipping?.shippingService)}
          />
        </CenterCell>
      </td>
    </tr>
  );
}
