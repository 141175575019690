import { useQuery } from "react-query";
import { getLogistic } from "../../../../requests/logistic";
import PictureLoadingSpinner from "../../../../components/loadingSpinner/pictureloadingSpinner";
import DataError from "../../../../components/errors/DataError/DataError";
import Product from "./Product/Product";
import styles from "./logisticSection.module.scss";
import Button from "../../../../components/Button/Button";
import { useState } from "react";
import { ModalContainer } from "../../../../components/modals/ModalContainer";
import LogisticModal from "../../../../components/modals/logisticModal/logisticModal";
import clsx from "clsx";
import { isDesktop } from "react-device-detect";
import OverviewSection from "../OverviewSection/OverviewSection";
import OverviewSectionHeader from "../OverviewSectionHeader/OverviewSectionHeader";

interface LogisticSectionProps {
  logisticId: string;
  requiredActions?: boolean;
}

export default function LogisticSection({
  logisticId,
  requiredActions = false,
}: LogisticSectionProps) {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const { data, isLoading, refetch } = useQuery(
    ["logistic", logisticId],
    () => getLogistic(logisticId),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(logisticId),
    }
  );
  if (!logisticId) {
    return <></>;
  }
  if (isLoading) {
    return (
      <OverviewSection className={styles["loading-section"]}>
        <PictureLoadingSpinner />
      </OverviewSection>
    );
  }

  if (!data) {
    return (
      <OverviewSection className={styles["loading-section"]}>
        <DataError error={"logistic"} />
      </OverviewSection>
    );
  }
  const { productsList } = data;

  return (
    <OverviewSection className={styles["logistic-section"]}>
      <OverviewSectionHeader
        title={`Articles (${productsList.length})`}
        onClick={openLogisticModal}
      ></OverviewSectionHeader>
      <ul className={styles["product-list"]}>
        {productsList.map((product, index) => (
          <Product key={index} product={product} />
        ))}
      </ul>
      <ModalContainer isOpen={modalIsOpen} onCancel={openLogisticModal}>
        <LogisticModal
          id={logisticId}
          requiredActions={requiredActions}
          // dueDate={dueDate}
          onToolSelect={openLogisticModal}
        />
      </ModalContainer>
    </OverviewSection>
  );

  async function openLogisticModal() {
    if (modalIsOpen) {
      refetch();
    }
    setModalIsOpen(!modalIsOpen);
  }
}
