import styles from "./create.module.scss";
import { authloader } from "../..";
import {
  fetchOperationGroups,
  fetchOperationTypes,
  fetchProductGroups,
} from "../../../requests/operation";
import { useLoaderData } from "react-router-dom";
import { OperationForm as OperationFormType } from "../../../types/accounting.types";
import OperationForm from "./_components/OperationForm/OperationForm";

export async function loader({ request }: { request: Request }) {
  try {
    const productGroups = await fetchProductGroups({ request });
    const operationGroups = await fetchOperationGroups({ request });
    const operationTypes = await fetchOperationTypes({ request });
    authloader();
    return { productGroups, operationGroups, operationTypes };
  } catch (error: any) {
    throw new Response(error);
  }
}

interface OperationProps {
  operation?: OperationFormType;
}

export default function Create({ operation }: OperationProps) {
  const { productGroups, operationGroups, operationTypes } =
    useLoaderData() as {
      productGroups: string[];
      operationGroups: string[];
      operationTypes: string[];
    };

  return (
    <div className={styles["container"]}>
      <div className={styles["title"]}>Ajouter une opération</div>
      <OperationForm
        operation={operation}
        operationGroups={operationGroups}
        operationTypes={operationTypes}
        productGroups={productGroups}
      />
    </div>
  );
}
