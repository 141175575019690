import { useEffect, useState } from "react";
import { IProduct } from "../../../../../types/logistic.types";
import ProductCard from "../../productCard/ProductCard";
import style from "./operationDateCard.module.scss";
import { useQuery } from "react-query";
import { queryObject } from "../../../../../types/query.types";
import { getProductList } from "../../../../../requests/product";
import PagingComponent from "../../../../../components/pagingComponent/pagingComponent";
import { useModalComponent } from "../../../../../context/modalComponent.context";
import { useRefetchOnModalClose } from "../../../../../hooks/useRefetchOnModalClose";
import LoadingSpinner from "../../../../../components/loadingSpinner/loadingSpinner";
import PictureLoadingSpinner from "../../../../../components/loadingSpinner/pictureloadingSpinner";
import { ChevronRight, ExpandMore } from "@mui/icons-material";

const INITIAL_QUERY: queryObject = {
  limit: 50,
  offset: 0,
  sort: "",
  operationGroup: "",
  // operationType: "SHOES_AUTRE",
  field: "",
  value: "",
};

interface OperationDateCardProps {
  // operationType: string;
  title: string;
  workshopId?: string;
  from: string;
  to: string;
  showPro?: boolean
}

export default function OperationDateCard({
  // operationType,
  title,
  workshopId,
  from,
  to,
  showPro
}: OperationDateCardProps) {
  const [query, setQuery] = useState<queryObject>(INITIAL_QUERY);
  const [showOperationList, setShowOperationList] = useState<boolean>(false);
  const { data, isLoading, refetch } = useQuery(
    [`cobbler-${from}-${to}`, query, workshopId, showPro, from, to],
    () => getProductList(formatedQuery(from, to)),
    { keepPreviousData: true }
  );
  useRefetchOnModalClose(refetch);

  return (
    <>
      <div
        className={style["operation-type-card"]}
        onClick={() => setShowOperationList(!showOperationList)}
      >
        <div className={style["operation-type-card-left"]}>
          <div className={style["title"]}>
            <div className={style["chevron"]}>
              {showOperationList ? <ExpandMore /> : <ChevronRight />}
            </div>
            <span>{title}</span>
          </div>
          {/* {data && data.productsList[0]?.dueDate && (
            <span className={style["earliest-product-dueDate"]}>
              Date de livraison la plus proche{" "}
              {new Date(data.productsList[0].dueDate).toLocaleDateString(
                "fr-FR",
                {
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
                }
              )}
            </span>
          )} */}
        </div>
        <div className={style["operation-type-card-right"]}>
          {data ? (
            data.totalCount
          ) : (
            <PictureLoadingSpinner
              color="white"
              className={style["loading-spinner"]}
            />
          )}
        </div>
      </div>
      {showOperationList && (
        <>
          <PagingComponent
            query={query}
            setQuery={setQuery}
            totalCount={data?.totalCount}
          />
          <div className={style["product-list"]}>
            {data &&
              data.productsList.map((p, index: number) => {
                return (
                  <ProductCard
                    key={index}
                    product={p}
                    showOperationAcronym={true}
                  />
                );
              })}
          </div>
          <hr />
        </>
      )}
    </>
  );

  function formatedQuery(from: string, to: string) {
    let formatedQuery: string = `?limit=${query.limit}&offset=${
      query.offset * query.limit
    }&productGroup=SHOES&status=WORKSHOP_REPAIR;WORKSHOP_CARE;EXTERNAL_JOB&operationsList.canceled=false&dueDate=${from},${to}`; //

    if (workshopId) {
      formatedQuery += `&workshopId=${showPro ? "" : "1-1"}-${workshopId}`;
    } else if (!showPro) {
      formatedQuery += `&workshopId=1-1-`;
    }

    return formatedQuery;
  }
}
