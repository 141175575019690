import style from "./item.module.scss";
import { Edit } from "@mui/icons-material";
import MoreTools, {
  IMoreToolsOptions,
} from "../../../components/MoreTools/MoreTools";
import { Referral } from "../../../types/referral.types";
import State from "../../../components/StateItem/State";
import Price from "../../../components/Price/Price";

export default function Item({ referral }: { referral: Referral }) {
  const MORE_TOOLS_OPTIONS: IMoreToolsOptions[] = [
    {
      to: `/referral/edit/${referral.id}`,
      icon: <Edit />,
      name: "Modifier",
    },
  ];

  return (
    <tr className={style["item"]}>
      <td className={style["item-cell"]}>
        {referral.createdAt &&
          new Date(referral.createdAt).toLocaleDateString()}
      </td>
      <td className={style["item-cell"]}>
        {referral.updatedAt &&
          new Date(referral.updatedAt).toLocaleDateString()}
      </td>
      <td className={style["item-cell"]}>
        <span>{referral.referredEmail}</span>
      </td>
      <td className={style["item-cell"]}>
        <Price price={referral.amount} />
      </td>
      <td className={style["item-cell"]}>
        <CenterCell>
          <State state={referral.state} />
        </CenterCell>
      </td>
      <td className={style["item-cell"]}>
        <CenterCell>
          <MoreTools options={MORE_TOOLS_OPTIONS} />
        </CenterCell>
      </td>
    </tr>
  );
}

interface CenterCellProps {
  children: React.ReactNode;
}

function CenterCell({ children }: CenterCellProps) {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>{children}</div>
  );
}
