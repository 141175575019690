import { useMutation, useQuery } from "react-query";
import styles from "./quoteAction.module.scss";
import { fetchTracker } from "../../../../../requests/tracker";
import { toast } from "react-toastify";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_SUCCESS_OPTIONS,
} from "../../../../../utils/toast.options";
import {
  finalizeInvoice,
  generateInvoice,
} from "../../../../../requests/pennylane";
import Button from "../../../../../components/Button/Button";
import { ModalContainer } from "../../../../../components/modals/ModalContainer";
import { useState } from "react";
import LoadingSpinner from "../../../../../components/loadingSpinner/loadingSpinner";
import DataError from "../../../../../components/errors/DataError/DataError";
import PictureLoadingSpinner from "../../../../../components/loadingSpinner/pictureloadingSpinner";

interface QuoteActionProps {
  trackId: string;
}

export default function QuoteAction({ trackId }: QuoteActionProps) {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const { data, refetch, isLoading } = useQuery("trackerQuery", () =>
    fetchTracker({ params: { id: trackId } })
  );
  const generateInvoiceMutation = useMutation(generateInvoice, {
    onError: () => {
      toast.error("Une erreur est survenue.", TOAST_ERROR_OPTIONS);
    },
    onSuccess: () => {
      toast.success("Brouillon générée !", TOAST_SUCCESS_OPTIONS);
      setTimeout(() => refetch(), 500);
    },
  });

  const finalizeInvoiceMutation = useMutation(finalizeInvoice, {
    onError: () => {
      toast.error("Une erreur est survenue.", TOAST_ERROR_OPTIONS);
    },
    onSuccess: () => {
      toast.success("Facture finalisée !", TOAST_SUCCESS_OPTIONS);
      setTimeout(() => refetch(), 500);
    },
  });

  if (isLoading || generateInvoiceMutation.isLoading) {
    return <PictureLoadingSpinner />;
  }

  if (!data) {
    return <DataError error="invoice" />;
  }

  const { tracker } = data;

  return (
    <div className={styles["quote-action"]}>
      {data?.tracker?.invoice?.fileUrl ? (
        <div className={styles["action-container"]}>
          <Button
            className={styles["invoice-button"]}
            onClick={handleOpenInvoiceModal}
          >
            Afficher facture
          </Button>
          {tracker?.invoice?.state === "DRAFT" && (
            <Button onClick={handleFinalizeInvoice}>Valider facture</Button>
          )}
        </div>
      ) : (
        <div>
          <Button onClick={handleGenerateInvoice}>Nouvelle facture</Button>
        </div>
      )}
      <ModalContainer isOpen={modalIsOpen} onCancel={handleOpenInvoiceModal}>
        <iframe
          className={styles["invoice-frame"]}
          src={tracker?.invoice?.fileUrl}
        >
          {tracker?.invoice?.fileUrl}
        </iframe>
      </ModalContainer>
    </div>
  );

  function handleGenerateInvoice() {
    generateInvoiceMutation.mutate(trackId);
  }

  function handleFinalizeInvoice() {
    finalizeInvoiceMutation.mutate(data?.tracker?.invoice?.invoiceId);
  }

  function handleOpenInvoiceModal() {
    setModalIsOpen(!modalIsOpen);
  }
}
