import { useNavigate } from "react-router-dom";
import { ITracker } from "../../../../../types/tracker.types";
import styles from "./payedListItem.module.scss";
import State from "../../../../../components/StateItem/State";
import CenterCell from "../../../../../components/CenterCell/CenterCell";
import clsx from "clsx";
import { isDesktop } from "react-device-detect";
import { translateTrackingState } from "../../../../../utils/tracking.init";

interface PayedListItemProps {
  tracker: ITracker;
}

export default function PayedListItem({ tracker }: PayedListItemProps) {
  const navigate = useNavigate();

  return (
    <tr
      onClick={() => navigate(`/overview/${tracker.id}`)}
      className={clsx(
        styles["item"],
        isDesktop ? styles["hover"] : styles["active"]
      )}
    >
      <td
        className={`${styles["item-cell"]} ${styles["item-date"]}`}
        title={`${
          tracker?.payment.transactionDate
            ? new Date(tracker?.payment.transactionDate).toLocaleDateString(
                "fr-FR"
              )
            : "indisponible"
        }`}
      >
        {tracker?.payment.transactionDate
          ? new Date(tracker?.payment.transactionDate).toLocaleDateString(
              "fr-FR"
            )
          : "indisponible"}
      </td>
      <td className={styles["item-cell"]}>{tracker.contact?.displayname}</td>
      <td className={`${styles["item-cell"]} ${styles["status-box"]}`}>
        <CenterCell>
          <State state={tracker.state} isTracker />
        </CenterCell>
      </td>
    </tr>
  );
}
