import { UnfoldMore } from "@mui/icons-material";
import getStatusColor from "../../translation/statusColor";
import {
  TRACKING_STATE,
  translateTrackingState,
} from "../../utils/tracking.init";
import Button from "../Button/Button";
import styles from "./stateSelect.module.scss";
import clsx from "clsx";
import { useRef, useState } from "react";
import DropDownPortal from "../DropDownPortal/DropDownPortal";
import useElementDimensions from "../../hooks/useElementDimensions";
import { updateTrackerState } from "../../requests/tracker";
import BeautifulDiv from "../beautifulDiv/BeautifulDiv";

interface StateSelectProps {
  state: string;
  list: string[];
  onStateChange: (state: string) => void;
  className?: string;
}

export default function StateSelect({
  state,
  list,
  onStateChange,
  className,
}: StateSelectProps) {
  const [expand, setExpand] = useState<boolean>(false);
  const [refDrop, widthDrop] = useElementDimensions<HTMLUListElement>([expand]);
  const [ref, width] = useElementDimensions<HTMLDivElement>([expand]);

  return (
    <div className={clsx(styles["select-state"], className)} ref={ref}>
      <div className={styles["select-state-container"]}>
        <Button
          className={styles["state-button"]}
          style={{ backgroundColor: getStatusColor(state) }}
          onClick={handleToggleDropDown}
        >
          {translateTrackingState(state)}
          <UnfoldMore className={styles["icon"]} />
        </Button>
      </div>
      <DropDownPortal<HTMLDivElement>
        buttonRef={ref}
        className={styles["state-select-dropdown"]}
        expand={expand}
        onClickOutside={handleCloseDropDown}
        offset={{ top: 4, left: (widthDrop || 0) - (width || 0) }}
      >
        <ul className={styles["dropdown"]} ref={refDrop}>
          {list.map((stateItem, index) => (
            <li
              className={clsx(
                styles["dropdown-item"],
                state === stateItem && styles["selected"]
              )}
              key={index}
              onClick={() => onStateChange(stateItem)}
            >
              {translateTrackingState(stateItem)}
            </li>
          ))}
        </ul>
        <BeautifulDiv />
      </DropDownPortal>
    </div>
  );

  function handleToggleDropDown() {
    setExpand(!expand);
  }

  function handleCloseDropDown() {
    setExpand(false);
  }
}
