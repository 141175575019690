import { FormEvent, useRef, useState } from "react";
import styles from "./paymentModal.module.scss";
import { ITracker } from "../../../types/tracker.types";
import InputContainer from "../../forms/inputs/inputContainer/inputContainer";
import { useMutation, useQuery } from "react-query";
import { addPayment, addPaymentOrder } from "../../../requests/payment";
import { IForcedPayment } from "../../../types/payment.type";
import { toast } from "react-toastify";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_SUCCESS_OPTIONS,
} from "../../../utils/toast.options";
import { useModalComponent } from "../../../context/modalComponent.context";
import LoadingSpinner from "../../loadingSpinner/loadingSpinner";
import { useRevalidator } from "react-router-dom";
import { fetchQuote, updateQuote } from "../../../requests/quote";
import AddImage from "../../forms/AddImage/AddImage";
import Button from "../../Button/Button";
import PictureLoadingSpinner from "../../loadingSpinner/pictureloadingSpinner";
import Price from "../../Price/Price";
import Input from "../../inputs/Input/Input";

interface PaymentModalProps {
  onSuccess?: () => void;
  tracker: ITracker;
}

function formatDate(value: string, pattern: string) {
  let i = 0;
  let v = value.toString().replace(/[^0-9 ]/g, "");
  return pattern.replace(/#/g, (_: any) => v[i++]);
}

export default function PaymentModal({
  onSuccess,
  tracker,
}: PaymentModalProps) {
  const { setModalIsOpen } = useModalComponent();
  const amountRef = useRef<HTMLInputElement>(null);
  const [amount, setAmount] = useState<string>("");
  const [paymentType, setPaymentType] = useState<string>("shop");
  const revalidator = useRevalidator();

  const {
    data: quote,
    isLoading,
    refetch,
  } = useQuery("paymentQuote", () => fetchQuote(tracker.quote.documentId));

  const addPaymentMutation = useMutation(addPayment, {
    onError: () => {
      toast.error("Une erreur est survenue.", TOAST_ERROR_OPTIONS);
    },
    onSuccess: () => {
      toast.success("Paiement validé !", TOAST_SUCCESS_OPTIONS);
      setModalIsOpen(false);
      onSuccess && onSuccess();
      revalidator.revalidate();
    },
  });

  const addPaymentOrderMutation = useMutation(addPaymentOrder, {
    onError: () => {
      toast.error("Une erreur est survenue.", TOAST_ERROR_OPTIONS);
    },
    onSuccess: (data) => {
      if (data.paymentURL) {
        toast.success(data.paymentURL, {
          ...TOAST_SUCCESS_OPTIONS,
          closeOnClick: false,
          draggable: false,
          autoClose: false,
        });
        navigator.clipboard.writeText(data.paymentURL).then(() => {
          toast.success(
            "Lien de paiement copié dans le presse-papier !",
            TOAST_SUCCESS_OPTIONS
          );
        });
      }
      onSuccess && onSuccess();
      setModalIsOpen(false);
    },
  });

  const updateQuoteMutation = useMutation(updateQuote, {
    onError: () => {
      toast.error(
        "Une erreur est survenue lors de la mise à jour du devis.",
        TOAST_ERROR_OPTIONS
      );
    },
    onSuccess: () => {
      refetch();
    },
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!quote) {
    return <></>;
  }

  return (
    <div className={styles["payment-modal"]}>
      {/* <div className={styles["payment-form"]}> */}
      {isLoading || addPaymentMutation.isLoading ? (
        <PictureLoadingSpinner />
      ) : (
        <form className={styles["payment-form"]} onSubmit={handleSubmit}>
          <div className={styles["payment-form-top"]}>
            <div>
              Montant devis: <Price price={quote?.total} />
            </div>
            <Input
              // ref={amountRef}
              value={isNaN(parseFloat(amount)) ? 0 : (parseFloat(amount) * 100)}
              onChange={({ target }) => setAmount(target.value)}
              label="Montant"
              type="number"
              name="amount"
              step={0.01}
            />
            <InputContainer>
              <label>Type</label>
              <select value={paymentType} onChange={handleChangePaymentType}>
                <option value="shop">Paiement Boutique</option>
                <option value="link">Lien de paiement</option>
                <option value="forced">Forcé</option>
              </select>
            </InputContainer>
            {paymentType === "shop" && (
              <AddImage
                className={styles["add-image-container"]}
                filename={`-${tracker.id}-quote.`}
                imagesList={quote?.imagesList}
                onAddImage={handleAddImageToQuote}
                onDeleteImage={handleRemoveImageFromQuote}
              />
            )}
          </div>
          <div className={styles["payment-form-bottom"]}>
            <Button type="submit">Valider</Button>
          </div>
        </form>
      )}
      {/* </div> */}
    </div>
  );

  function handleChangePaymentType({
    target,
  }: {
    target: EventTarget & HTMLSelectElement;
  }) {
    setPaymentType(target.value);
  }

  async function handleAddImageToQuote(filename: string) {
    if (!quote) {
      return;
    }
    try {
      const imagesList = quote?.imagesList
        ? [...quote?.imagesList, filename]
        : [filename];
      await updateQuoteMutation.mutateAsync({
        ...quote,
        imagesList,
      });
    } catch (error) {
      toast.error("Erreur lors de mise à jour du devis.", TOAST_ERROR_OPTIONS);
    }
  }

  async function handleRemoveImageFromQuote(filename: string) {
    if (!quote) {
      return;
    }
    try {
      const imagesList = quote?.imagesList
        ? quote?.imagesList.filter((f) => f !== filename)
        : [];
      await updateQuoteMutation.mutateAsync({
        ...quote,
        imagesList,
      });
    } catch (error) {
      toast.error("Erreur lors de mise à jour du devis.", TOAST_ERROR_OPTIONS);
    }
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    if (!amount || isNaN(parseInt(amount))) {
      toast.error("Montant invalide.", TOAST_ERROR_OPTIONS);
      return;
    }
    if (paymentType === "shop") {
      if (!quote?.imagesList || !quote?.imagesList.length) {
        toast.error(
          "Veuillez prendre au moins une photo de la facture.",
          TOAST_ERROR_OPTIONS
        );
        return;
      }
    }

    if (
      paymentType !== "link" &&
      paymentType !== "forced" &&
      paymentType !== "shop"
    ) {
      toast.error("Type invalide.", TOAST_ERROR_OPTIONS);
      return;
    }
    // let amount = parseInt(amountRef.current.value);
    if (
      !window.confirm(
        `Forcer un paiement de ${amount} € pour un devis de ${
          (quote?.total || 0) / 100
        } € ?`
      )
    ) {
      setModalIsOpen(false);
      return;
    }
    let value = parseInt(amount);
    let formattedDate = formatDate(new Date().toISOString(), "##############");
    const paymentData: IForcedPayment = {
      vads_order_info: "B2C-BOOKING",
      vads_trans_id: "000000",
      vads_trans_status: "AUTHORISED",
      vads_trans_date: formattedDate,
      vads_amount: value * 100,
      vads_cust_email: tracker.contact.email,
      vads_cust_id: tracker.contact.documentId,
      vads_order_id: tracker.id,
    };

    switch (paymentType) {
      case "link":
        return addPaymentOrderMutation.mutate(paymentData);
      case "forced":
        return addPaymentMutation.mutate({ paymentData });
      case "shop":
        return addPaymentMutation.mutate({ paymentData, sendEmail: false });
      default:
        return;
    }
  }
}
